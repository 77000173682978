<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
  <seller-add
      :isSidebarActive="addNewDataSidebar"
      @closeSidebar="toggleDataSidebar"
      :data="sidebarData"
    />
    <vs-popup
      class="holamundo"
      title="تعيين المستخدم لإحدى المتاجر"
      :active.sync="popupActive"
    >
      <div
        class="popup-style w-full h-full flex flex-col justify-between"
        style="height: 300px !important"
      >
        <div>
          <label for="status" class="w-full mb-1">إسم المتجر</label>
          <v-select
            label="name"
            v-model="sellerData.store_id"
            :options="stores"
            :reduce="(item) => item.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            class="w-full"
            data-vv-scope="addAdmin"
            name="storename"
            data-vv-as="إسم المتجر"
            v-validate="'required'"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('addAdmin.storename')"
            >{{ errors.first("addAdmin.storename") }}</span
          >
        </div>
        <div class="w-full text-center">
          <vs-button
            class="rounded mx-3"
            color="danger"
            type="border"
            @click="togglePopup"
            >إلغاء</vs-button
          >
          <vs-button @click="addStoreId" class="rounded mx-3" color="primary"
            >تعيين</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        multiple
        v-model="selected"
        :max-items="itemsPerPage"
        search
        :data="sellers"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div
            class="flex flex-wrap-reverse items-center data-list-btn-container"
          >
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer mr-4 mb-4"
            >
              <div
                class="
                  p-4
                  shadow-drop
                  rounded-lg
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                  text-lg
                  font-medium
                  w-32 w-full
                "
              >
                <span class="mr-2">العمليات</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>حذف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="ArchiveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>أرشيف</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="FileIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>طباعة</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="SaveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>عمليات أخرى</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ADD NEW -->
            <vs-button
              @click="addNewData"
              class="
                btn-add-new
                p-3
                mb-4
                mr-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">اضافة تاجر</span>
            </vs-button>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown
            vs-trigger-click
            class="cursor-pointer mb-4 mr-4 items-per-page-handler"
          >
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  sellers.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : sellers.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th>الصورة</vs-th>
          <vs-th sort-key="username">الإسم</vs-th>
          <vs-th sort-key="phone">رقم الهاتف</vs-th>
          <vs-th sort-key="store">إسم المتجر</vs-th>
          <vs-th sort-key="is_active">حالة المستخدم</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="seller" :key="index" v-for="(seller, index) in data">
              <vs-td
                class="table-image-container flex justify-center items-center"
              >
                <img height="80" :src="seller.image" class="table-image" />
              </vs-td>
              <vs-td>
                <p class="font-medium truncate">
                  {{ seller.name }}
                </p>
              </vs-td>

              <vs-td>
                <p>{{ seller.phone }}</p>
              </vs-td>

              <vs-td >
                <div
                v-if="seller.store.length >= 1"
                  
                >
                  <span v-for="(store, index) in seller.store"
                  v-bind:key="index">{{ store.name }}</span>
                </div>
                <div
                  v-if="seller.store.length <= 0"
                  @click="togglePopup(seller.id)"
                >
                  <vs-chip
                    transparent
                    color="primary"
                    class="product-order-status cursor-pointer"
                  >
                    تعيين لمتجر +
                  </vs-chip>
                </div>
              </vs-td>

              <vs-td>
                <feather-icon
                  icon="UserXIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  title="حظر"
                  @click.stop="confirm(seller.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="current_Page"
        @change="getData(current_Page)"
      ></vs-pagination>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import SellerAdd from "../../users-management/sellers/AddNewUser.vue";
import VueUploadComponent from "vue-upload-component";
import Datepicker from "vuejs-datepicker";
import { mapActions } from "vuex";

export default {
  components: {
    vSelect,
    "file-upload": VueUploadComponent,
    Datepicker,
    SellerAdd
  },
  data() {
    return {
      selected_id: "",
      sellerData: {
        user_id: "",
        store_id: "",
      },
      popupActive: false,
      addNewDataSidebar: false,
      sidebarData: {},
      selected: [],
      sellers: [],
      current_Page: 1,
      itemsPerPage: 10,
      lastPage: 0,
      totalItems: 0,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.sellers.length;
    },
    stores() {
      return this.$store.state.users.stores;
    },
  },
  methods: {
    ...mapActions("users", [
      "fetchStoreAdmins",
      "deleteUser",
      "fetchStores",
      "createStoreAdmin",
      "assignToStore",
    ]),
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.getData();
      this.addNewDataSidebar = val;
    },
    togglePopup() {
      this.popupActive = !this.popupActive;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile.size / 1024.0 > 5000) {
        this.$vs.notify({
          title: "حدث خطأ",
          text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
          color: "danger",
        });
        return prevent();
      }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يجب أن يكون الملف بإمتداد صورة صحيح",
            color: "danger",
          });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    confirm(id) {
      this.selected_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: ` هل انت متأكد من حظر هذا المستخدم؟  `,
        accept: this.change_status,
        acceptText: "نعم",
        cancelText: "الغاء",
      });
    },
    change_status() {
      this.deleteUser(this.selected_id)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت أرشفة المستخدم بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          
          this.errorDialog(error);
        });
    },
    addStoreId() {
      this.$validator.validateAll("addAdmin").then((result) => {
        if (result) {
          let data = new FormData();
          data.set("user_id", this.sellerData.user_id);
          data.set("_method", "put");
          data.set("store_id", this.sellerData.store_id);
          this.assignToStore(data)
            .then(() => {
              this.getData();
              this.togglePopup(null);
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تمت تعيين المتجر بنجاح",
                color: "success",
              });
            })
            .catch((error) => {
              this.errorDialog(error);
            });
        }
      });
    },
    getData(page) {
      this.fetchStoreAdmins(page).then((response) => {
        this.sellers = response.data.data.data;
        this.current_Page = response.data.data.current_page;
        this.lastPage = response.data.data.last_page;
      });
    },
  },
  mounted() {},
  created() {
    this.getData(1);
    this.fetchStores();
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

//sidebar
.vs-sidebar--background {
  z-index: 99999 !important;
}

.vs-sidebar-position-right {
  z-index: 999999 !important;
  max-width: 400px !important;
}

.vs-sidebar-primary .vs-sidebar-item-active {
  font-weight: 400 !important;
  border: none !important;
}

.vs-con-input {
  i {
    font-size: 1.6rem !important;
  }
}

#vs1__listbox {
  height: 200px !important;
}
</style>
